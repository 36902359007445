import React, {
  useCallback, useEffect, useState,
} from 'react';
import { mui } from 'tl-storybook';
import axios from 'axios';
import { LogEntry } from 'src/app/interfaces/log';
import LogTab from '../../../core/components/PropertiesDrawer/LogTab';
import { updateErrorMessage, updateErrorOpen } from '../../../core/slices/ErrorSlice';
import { WorkOrder } from '../../../interfaces/work-order';
import OrderHistoryTab from './OrderHistoryTab';
import AssetsPropertiesTab from './AssetsPropertiesTab';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';
import { API_ENDPOINTS } from '../../../core/consts/API_ENDPOINTS';
import {
  getAssetLogs,
  getWorkorderLogs,
  setAssetLogsLoading,
} from '../../../core/slices/PropertiesDrawerSlice';
import { AppDispatch } from '../../../store';

function a11yProps(index: any) {
  return {
    id: `properties-tab-${index}`,
    'aria-controls': `properties-tabpanel-${index}`,
  };
}

export default function PropertiesTabs() {
  const dispatch: AppDispatch = useAppDispatch();

  const selectedAsset = useAppSelector((state) => state.propertiesDrawer.selectedAsset);
  const selectedPmamAsset = useAppSelector((state) => state.propertiesDrawer.selectedPmamAsset);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [activityLogs, setActivityLogs] = useState<LogEntry[]>([]);
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);

  const getLogs = useCallback((asset:any, orders:any) => {
    const assetLogs = dispatch(getAssetLogs(asset)).then((resp) => resp.data);
    const workorderLogs = [] as any;
    orders.forEach((order: any) => {
      workorderLogs.push(dispatch(getWorkorderLogs(order)).then((resp) => resp.data));
    });

    Promise.all([assetLogs, ...workorderLogs]).then((data: any) => {
      let logs: any = [];
      let errors = '';
      data.forEach((d: any, i: any) => {
        if (Array.isArray(d)) {
          logs = [...logs, ...d];
        }
        if (d.error) {
          errors += `(${i + 1}) ${d.error}... `;
        }
      });
      if (errors) {
        dispatch(updateErrorMessage(errors));
        dispatch(updateErrorOpen(true));
      }
      setActivityLogs(logs.sort((a: any, b: any) => b.createdAt.localeCompare(a.createdAt)));
    });
  }, [selectedAsset]);

  const getAssetOrderLogs = useCallback(() => {
    dispatch(setAssetLogsLoading(true));
    axios.get<WorkOrder[]>(`${API_ENDPOINTS.WORK_ORDERS}?asset_materialId=${selectedAsset.materialId}`).then((resp) => {
      setWorkOrders(resp.data);
      getLogs(selectedAsset, resp.data = []);
    }).catch((error) => {
      setWorkOrders(null);
      dispatch(updateErrorMessage(error));
      dispatch(updateErrorOpen(true));
    }).finally(() => {
      dispatch(setAssetLogsLoading(false));
    });
  }, [selectedAsset]);

  useEffect(() => {
    if (selectedAsset) {
      getAssetOrderLogs();
    }

    return () => {
      setActivityLogs([]);
      dispatch(updateErrorMessage(''));
      dispatch(updateErrorOpen(false));
    };
  }, [selectedAsset]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setSelectedTab(newValue);
  };

  // TODO: the comment field is disabled...
  const createComment = (comment: string) => {
    const body: LogEntry = {
      description: comment,
      title: 'Comment',
      source: 'MAM',
      type: 'COMMENT',
      severity: null,
    };
    axios.post(`${API_ENDPOINTS.ACTIVITY_LOGS}?id=${selectedAsset.id}`, body).then(() => {
      getLogs(selectedAsset, []);
    });
  };

  return (
    <>
      <mui.Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="filters"
      >
        <mui.Tab label="Properties" {...a11yProps(0)} data-testid="properties-panel-properties-tab" />
        <mui.Tab label="Log" {...a11yProps(1)} data-testid="properties-panel-log-tab" />
        <mui.Tab label="Order History" {...a11yProps(2)} data-testid="properties-panel-order-history-tab" />
      </mui.Tabs>
      {selectedTab === 0 && <AssetsPropertiesTab item={selectedAsset} pmam={selectedPmamAsset} />}
      {selectedTab === 1 && <LogTab data={activityLogs} createComment={createComment} />}
      {selectedTab === 2 && <OrderHistoryTab workOrders={workOrders} />}
    </>
  );
}
