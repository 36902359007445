import { mui } from 'tl-storybook';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { BUTTON_STATUSES } from '../constants/button_statuses';
import { MEDIA_TYPES } from './consts/MEDIA_TYPES';
import { ConfigureDrawerItem, ConfigureItem } from '../interfaces/configure-drawer-item';
import { FilterDrawerItem } from '../interfaces/filter-drawer-item';
import { Asset } from '../interfaces/asset';
import { formatDuration } from './helpers';

export function isWatermarkPropertyType(propertyType: string): boolean {
  const watermarkPropertyTypes = [
    'Animated',
    'Documentary',
    'Reality TV',
    'Scripted Drama',
    'Scripted Comedy',
    'Event Award Show',
    'Topical',
    'Short Form Episodic - Music Video',
    'Tease',
    'Trailer',
    'Sneak Peek',
    'BTS',
    'Outtakes',
    'Scene Clips',
  ];

  return watermarkPropertyTypes.includes(propertyType);
}

export function getNestedData(keys: string[], item: any) {
  let finalValue: any;
  keys.forEach((key: string, index) => {
    switch (true) {
      case !index:
        finalValue = item[key];
        break;
      case !finalValue:
        finalValue = null;
        break;
      // This condition will allow for falsy values like false, zero and empty strings...
      case (finalValue[key] !== null && finalValue[key] !== undefined):
        finalValue = finalValue[key];
        break;
      default:
        finalValue = null;
    }
  });
  return finalValue;
}

export function getSelectorList(
  buttonStatus: string,
  availableColumns: FilterDrawerItem | ConfigureDrawerItem,
): string[] {
  if (buttonStatus === BUTTON_STATUSES.CAT) {
    return Object.keys(availableColumns).sort();
  }
  return Object.keys(availableColumns)
    .filter((key) => availableColumns[key].length && availableColumns[key][0].displayName !== 'Brand')
    .sort();
}

export function getCheckboxSelectionList(buttonStatus: string, availableColumns: ConfigureItem[]): ConfigureDrawerItem {
  const state: { [key: string]: ConfigureItem[] } = {};
  Array.from(
    new Set(
      availableColumns
        .map((col: ConfigureItem) => (buttonStatus === BUTTON_STATUSES.CAT ? col.group : col.displayName[0])),
    ),
  )
    .sort()
    .forEach((category) => {
      state[category] = [];
    });
  availableColumns.forEach((column: ConfigureItem) => {
    if (buttonStatus === BUTTON_STATUSES.CAT) state[column.group].push(column);
    else state[column.displayName[0]].push(column);
  });

  return state;
}

export function formatFieldNames(fieldName: string) {
  let str = fieldName;
  // replace underscores with spaces
  str = str.replace(/_/g, ' ');
  // replace fields starting with prom with ProM
  str = str.replace(/^prom/i, 'ProM');
  str = str.replace('ProMo', 'Promo');
  // replace fields ending with id with ID
  str = str.replace(/id$/i, 'ID');
  // replace fields ending with ids with IDs
  str = str.replace(/(\b)(ids$)/i, 'IDs');
  return str;
}

export function getMediaTypeIcon(mediaType: string): any {
  if (mediaType) {
    const { icon } = MEDIA_TYPES.find((media) => media.code.toUpperCase() === mediaType.toUpperCase()) || {};
    return icon;
  }

  return mui.SvgIcon;
}

export function formatDateTime(date: string) {
  const localDate = new Date(date);

  return moment(localDate).format('MM/DD/YY hh:mm A');
}

export function formatAspectRatio(aspectRatio: string) {
  if (!aspectRatio) {
    return '';
  }

  const match = aspectRatio.match(/^AR_(?<width>\d+)_TO_(?<height>\d+)/);
  if (!match) {
    return aspectRatio;
  }

  return `${match.groups.width}:${match.groups.height}`;
}

export function deleteNestedValue(object: any, key: string | number, value: any) {
  function findAndDelete(obj: any) {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (typeof item === 'object') {
          Object.values(item).forEach((v) => {
            if (v === value) {
              obj.splice(0, obj.length, ...obj.filter((el) => el[key] !== value));
            }
          });
        }
        findAndDelete(item);
      });
    } else if (typeof obj === 'object') {
      Object.keys(obj).forEach((k) => {
        const val = obj[k];
        if (Array.isArray(val) && val.length < 1) {
          delete obj[k];
        }
      });
    }
  }
  findAndDelete(object);
}

export function extensionHasImage(asset: Asset) {
  const IMAGE_PROXY_EXTENSIONS = ['psd', 'psb', 'pdf', 'eps', 'eps2', 'eps3', 'ai', 'doc', 'docm', 'docx', 'dot',
    'dotx', 'pptx', 'ppt', 'pps', 'xlsx', 'xlsm', 'xls', 'xml', 'csv'];

  const fileExtension = asset.pmam?.fileExtension || '';
  return IMAGE_PROXY_EXTENSIONS.includes(fileExtension.toLocaleLowerCase());
}

export function changeValueSource(sourceSystem: string, data: any) {
  const categories = cloneDeep(data);
  const featureTitle = categories.CONTENT.find((c: any) => c.displayName === 'Feature Title');
  featureTitle.name = 'pmamAsset.gtm.featureTitle';

  const episodeTitle = categories.CONTENT.find((c: any) => c.displayName === 'Episode Title');
  episodeTitle.name = 'pmamAsset.gtm.episodeTitle';

  if (sourceSystem === 'SUPPLY') { // remove the pmam scan type entry
    const index = categories.TECHNICAL.findIndex((c: any) => c.name === 'pmam.videoScanType');
    categories.TECHNICAL.splice(index, 1);
  }

  if (sourceSystem === 'PMAM') { // remove the supply scan type entry
    const index = categories.TECHNICAL.findIndex((c: any) => c.name === 'scanType');
    categories.TECHNICAL.splice(index, 1);
  }

  // NOTE: Delete and re-add the USERS category to the end of the object to set the the order of the keys.
  // Object.keys() creates an object based on the oder of insertion of the keys.
  const USERS = [...categories.USERS];
  delete categories.USERS;

  categories.USERS = USERS;

  return categories;
}

// convert milliseconds to frames at a 30 frames per second
export function formatFrames(secs: number) {
  const secString = secs.toString();
  const seconds = Number(secString.split('.')[0]);
  const milliseconds = ((secs - seconds) * 1000);

  return Math.trunc(milliseconds / 33.33).toLocaleString('en-US', { minimumIntegerDigits: 2 });
}

export class DrawerSortingService {
  static sortData(data: any) {
    const state = {
      az: {},
      category: {},
    };
    Object.keys(state).forEach((sortType) => {
      Array.from(new Set(data.map((col: any) => (sortType === 'category' ? col.group : col.displayName[0]))))
        .sort()
        .forEach((category) => {
          (state as any)[sortType][(category as any)] = [];
        });
      data.forEach((column: any) => {
        if (sortType === 'category') {
          (state as any)[sortType][column.group].push(column);
        } else {
          (state as any)[sortType][column.displayName[0]].push(column);
        }
      });
      // Sort each by displayName
      Object.keys((state as any)[sortType]).forEach((key) => {
        (state as any)[sortType][key] = this.sortArrayByDisplayName((state as any)[sortType][key]);
      });
    });

    return state;
  }

  static sortArrayByDisplayName(array: any) {
    return array.sort((a: any, b: any) => (a.displayName > b.displayName ? 1 : -1));
  }

  static removeNonValuesFromBrands(data: any) {
    // Brand values are getting overwritten on the frontend in the AssetsLanding component
    // TODO: Move call to get networks in AssetsLanding component for filter settings here
    const brand = data.find((field: any) => field.displayName === 'Brand');
    brand.values = brand.values.filter((b: any) => b.position !== 9999 && b.position !== 9998);
    return data;
  }

  static renameFields(data: any) {
    const gtmTitle = data.find((field: any) => field.displayName === 'Title');
    if (gtmTitle) {
      gtmTitle.displayName = 'GTM Title';
    }

    const title = data.find((field: any) => field.displayName === 'File Title');
    if (title) {
      title.displayName = 'Title';
    }

    const physical = data.find((field: any) => field.displayName === 'Physical');
    if (physical) {
      physical.displayName = 'Physical Storage';
    }

    return data;
  }
}

export function formatFileFormat(fileFormat: string) {
  const FILE_FORMATS_MAP = new Map([['MXF_SUBCLIP', 'MXF: XDCAM HD'], ['SOCIAL_SUBCLIP', 'MP4: H.264 HD Standard']]);
  return FILE_FORMATS_MAP.get(fileFormat) || fileFormat;
}

export const setUndefinedToEmptyString = (asset: Asset) => {
  const fields = Object.keys(asset);
  fields.forEach((field) => {
    if (asset[field] && typeof asset[field] === 'object') {
      setUndefinedToEmptyString(asset[field]);
    } else if (asset[field] === 'UNDEFINED') {
      asset[field] = '';
    }
  });
};

export const formatTableData = (tableDataAsset: Asset[]): Asset[] => tableDataAsset.map((asset: Asset) => {
  setUndefinedToEmptyString(asset);
  return {
    ...asset,
    duration: formatDuration(asset.duration),
    totalRunTime: formatDuration(asset.totalRunTime),
    aspectRatio: formatAspectRatio(asset.aspectRatio),
  };
});
