import React, { useState, useEffect } from 'react';
import { Box, mui, icons } from 'tl-storybook';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useNavigate, useLocation } from 'react-router-dom';
import { LookupResponse, SearchableField } from 'src/app/interfaces/searchable-field';
import { BUTTON_STATUSES } from '../../../constants/button_statuses';
import { FilterDrawerValue } from '../../../interfaces/drawer-item';
import { SavedFilter } from '../../../interfaces/saved-filter';
import {
  EXACT_FILTERS, FILTER_ITEM_TYPES, KEYWORD_CONTAINS_TYPES, WORKORDERS_CONTAINS_FILTERS,
} from '../../consts/FILTER_ITEM_TYPES';
import { formatFieldNames } from '../../utils';
import DrawerTitle from '../app/DrawerTitle';
import CategorySelectorButtons from '../CategorySelectorButtons';
import DragDropColumn from '../DragDropColumn/DragDropColumn';
import DrawerConfigurationContent from '../DrawerConfigurationContent';
import BrandFilter from './BrandFilter';
import FilterInput from './FilterInput';
import MediaTypeFilter from './MediaTypeFilter';
import SavedFilterItem from './SavedFilterItem';
import EditSavedFilterForm from './EditSavedFilterForm';
import ConfigureSearch from '../ConfigureColumnsDrawer/ConfigureSearch';
import { setAlert } from '../../slices/ErrorSlice';
import { useAppSelector, useAppDispatch } from '../../../interfaces/hooks';
import {
  updateSelectedFilters,
  setFilterToEdit,
  updateFilterObject,
  setConfirmRemoveFilter,
  getSavedFilters,
} from '../../slices/FilterSlice';
import { CONTEXT } from '../../consts/CONTEXT';

import { classes } from './FilterDrawer.styles';

export enum TAB {
  FILTER,
  SAVED_FILTER
}

interface Props {
  context?: CONTEXT;
  filterOpen: boolean;
  filterTab: TAB;
  setFilterTab: (tab: TAB) => void;
  toggleDrawer: (toggle: boolean) => void;
  pageRef: React.RefObject<any>;
  loadFilter: (savedFilter: any) => void;
  updateSavedFilters: (filters: SavedFilter[]) => void;
  loadingData: boolean;
  setNewQuery: (query: { [key: string]: FilterDrawerValue }[]) => void;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FilterDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    context,
    filterOpen,
    filterTab,
    setFilterTab,
    toggleDrawer,
    updateSavedFilters,
    loadingData,
    loadFilter,
    setNewQuery,
  } = props;

  const filterData = useAppSelector((state) => state.filter.filterData);
  const savedFilters = useAppSelector((state) => state.filter.savedFilters);
  const filterObjects = useAppSelector((state) => state.filter.filterObjects);

  const [buttonStatus, setButtonStatus] = useState<string>(BUTTON_STATUSES.CAT);
  const [searchColumnsText, setSearchColumnsText] = useState<string>('');
  const [applyButtonDisabled, setApplyButtonDisabled] = useState<boolean>(true);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setFilterTab(newValue);
  };

  const renameFilter = (filter: SavedFilter) => {
    dispatch(setFilterToEdit(filter));
  };

  const updateFilters = (f: SavedFilter[]) => {
    const filters = f.map((filter) => ({ ...filter }));
    filters.forEach((filter, index) => {
      filter.order = index;
    });
    updateSavedFilters(filters);
  };

  const saveFilter = (filter: SavedFilter) => {
    const filters = [...savedFilters];
    if (filter.id) {
      const index = filters.findIndex((f) => f.id === filter.id);
      filters.splice(index, 1, filter);
    } else {
      const duplicateFilter = filters.find((f) => f.title === filter.title);
      if (duplicateFilter) {
        dispatch(setAlert({ severity: 'error', message: 'Error: A filter already exists with this name.' }));
        dispatch(setFilterToEdit(duplicateFilter));
        return;
      }
      filters.push(filter);
    }

    updateFilters(filters);
  };

  useEffect(() => {
    dispatch(getSavedFilters(context));
  }, [context]);

  const procreate = (filter: SavedFilter, dragProps: DraggableProvidedDragHandleProps) => {
    const filterContext = { ...filter, context };
    return (
      <SavedFilterItem
        rename={renameFilter}
        remove={setConfirmRemoveFilter}
        loadFilter={loadFilter}
        filter={filterContext}
      >
        <Box {...dragProps}>
          <icons.DragHandle className="cursor-pointer" />
        </Box>
      </SavedFilterItem>
    );
  };

  const clearAllFilters = () => {
    dispatch(updateSelectedFilters([]));
    dispatch(updateFilterObject([]));
    setTimeout(() => {
      navigate(location.pathname);
    }, 0);
  };

  const closeDrawer = (toggle: boolean) => {
    setSearchColumnsText('');
    toggleDrawer(toggle);
  };

  const createQueryObj = (
    item: SearchableField,
    filterValue: FilterDrawerValue | string,
    name = item.name,
  ) => {
    let value = filterValue;
    let filterName = name;
    if (!filterName) return;
    if (filterName.includes('-after') || filterName.includes('-before') || filterName.includes('-gt-iso') || filterName.includes('-lt-iso')) {
      const year = value.toString().split('-')[0];
      if (Number(year) < 1000) {
        return;
      }
      if (window.location.href.includes('work-orders') || window.location.href.includes('studio-supply-orders')) {
        value = String(value).split('T')[0];
      }
    }
    const newQueryObj: { [key: string]: FilterDrawerValue } = {};

    // TODO boolean states need design work, making it work as it did for now
    if (value === false) {
      value = undefined;
    }
    if (window.location.pathname === '/assets' && EXACT_FILTERS.includes(item.name)) {
      filterName += '-exact';
    } else if (WORKORDERS_CONTAINS_FILTERS.includes(item.name)) {
      filterName += '-contains';
    } else if (KEYWORD_CONTAINS_TYPES.includes(item.type)) {
      filterName += '-keywordContains';
    }

    if (item.comparators.includes('CODE')) {
      filterName += '-code';
    }

    newQueryObj[filterName] = Array.isArray(value)
      ? (value as LookupResponse[]).map((i) => (i.id ? i.id : i.code))
      : (value as LookupResponse)?.id || (value as LookupResponse)?.code || value;
    return newQueryObj;
  };

  const changeFilter = (item: SearchableField, filterValue: FilterDrawerValue | string) => {
    const newQueryObjArr: any[] = [];
    newQueryObjArr.push(createQueryObj(item, filterValue));
    setNewQuery(newQueryObjArr);
  };

  const applyFilters = () => {
    const newQueryObjArr: any[] = [];
    filterObjects.forEach((filterObject) => {
      newQueryObjArr.push(createQueryObj(filterObject, filterObject.value));
    });
    setNewQuery(newQueryObjArr);
    setApplyButtonDisabled(true);
  };

  function searchCategories(searchVal: string): void {
    setSearchColumnsText(searchVal);
  }

  const isAssetsLanding = window.location.href.includes('/assets');

  const afterSuffix = isAssetsLanding ? '-after' : '-gt-iso';
  const beforeSuffix = isAssetsLanding ? '-before' : '-lt-iso';

  const renderDrawerFields = (fields: SearchableField[], category: string) => {
    // text types do not have an associated FilterInput so skip
    let items = fields.filter((field) => field.type !== FILTER_ITEM_TYPES.TEXT);
    if (searchColumnsText) {
      // filter to ones that match search text
      const regex = new RegExp(searchColumnsText, 'i');
      items = items.filter((item) => item.displayName.match(regex));
    }
    if (!items.length) {
      // don't show empty categories
      return <div />;
    }

    return (
      <Box>
        <h3 className="category-item_right_heading">{formatFieldNames(category.toLowerCase())}</h3>
        {items.map((item) => (
          <Box key={item.name} className="category-item_right_dropdown">
            <mui.Accordion TransitionProps={{ unmountOnExit: true }}>
              <mui.AccordionSummary
                expandIcon={<icons.ExpandMore data-testid={`filter-drawer-accordion-${item.name}`} />}
                sx={{
                  '&.MuiAccordionSummary-root': {
                    display: 'flex',
                    padding: '0 4px 0 16px',
                  },
                  '.MuiSvgIcon-root': {
                    padding: '12px',
                  },
                }}
              >
                <Box component="span" className="roboto-font">{formatFieldNames(item.displayName)}</Box>
              </mui.AccordionSummary>
              <mui.AccordionDetails>
                <Box className="filter-drawer-input">
                  {item.type === FILTER_ITEM_TYPES.DATETIME ? (
                    <>
                      <Box>After</Box>
                      <FilterInput
                        field={item}
                        filterName={`${item.name}${afterSuffix}`}
                        setApplyButtonDisabled={setApplyButtonDisabled}
                      />
                      <Box style={{ marginTop: 20 }}>Before</Box>
                      <FilterInput
                        field={item}
                        filterName={`${item.name}${beforeSuffix}`}
                        setApplyButtonDisabled={setApplyButtonDisabled}
                      />
                    </>
                  ) : (
                    <FilterInput
                      field={item}
                      filterName={item.name}
                      setApplyButtonDisabled={setApplyButtonDisabled}
                    />
                  )}
                </Box>
              </mui.AccordionDetails>
            </mui.Accordion>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <mui.Drawer
      sx={{ ...classes.root }}
      anchor="left"
      variant="persistent"
      open={filterOpen}
      onClose={() => closeDrawer(false)}
      PaperProps={{
        style: {
          position: 'absolute',
          top: '0',
          width: '440px',
          height: 'calc(100vh - 56px)',
        },
      }}
    >
      <DrawerTitle title="Filter Drawer" toggleDrawer={(open: boolean) => closeDrawer(open)} />
      <mui.Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={filterTab}
        onChange={handleTabChange}
        aria-label="filters"
      >
        <mui.Tab label="Filter" {...a11yProps(0)} data-automation-id="filter-drawer-filter-tab" />
        <mui.Tab label="Saved Filters" {...a11yProps(1)} data-automation-id="filter-drawer-saved-tab" />
      </mui.Tabs>
      <Box className="tab-view">
        {filterTab === TAB.FILTER ? (
          <Box role="tabpanel" id="simple-tabpanel-0" aria-labelledby="simple-tab-0">
            {loadingData && (
              <Box className="loading-overlay" data-testid="filter-drawer-loading-spinner">
                <mui.CircularProgress />
              </Box>
            )}
            <Box className="brand-selector-container">
              <BrandFilter changeFilter={changeFilter} />
            </Box>
            <Box className="media-type-container">
              <MediaTypeFilter changeFilter={changeFilter} />
            </Box>
            <Box className="filter-apply-clear-container">
              <mui.ButtonGroup>
                <mui.Button
                  className="filter-apply-all"
                  data-testid="filter-apply-all"
                  variant="outlined"
                  disabled={applyButtonDisabled}
                  onClick={applyFilters}
                >
                  Apply
                </mui.Button>
                <mui.Button
                  className="filter-clear-all"
                  data-testid="filter-clear-all"
                  variant="outlined"
                  disabled={loadingData}
                  onClick={clearAllFilters}
                >
                  Clear All
                </mui.Button>
              </mui.ButtonGroup>
            </Box>
            <Box className="drawer-top">
              <CategorySelectorButtons
                buttonStatus={buttonStatus}
                onClick={setButtonStatus}
              />
              <ConfigureSearch className="filter-search-input" onChange={(searchVal) => searchCategories(searchVal)} />
            </Box>
            <Box className="drawer-container">
              <DrawerConfigurationContent
                items={filterData[buttonStatus]}
                generateFields={renderDrawerFields}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <EditSavedFilterForm saveFilter={saveFilter} />
            <DragDropColumn
              iterableArray={savedFilters}
              procreate={procreate}
              dragEnd={updateFilters}
              itemKey="id"
            />
          </Box>
        )}
      </Box>
    </mui.Drawer>
  );
}
